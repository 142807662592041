import { ChartPieIcon } from "@heroicons/react/outline";
import moment from "moment";
import { Component } from "react";
import ReactApexChart from "react-apexcharts";
import AgentAnalyticsModel from "../../models/AgentAnalytics";
import { getMonthsAnalytics } from "../../store/actions/analyticsActions";
import Table, { TableHeader } from "../base/Table";
import UpdateJerryCanModal from "./UpdateJerryCanModal";

interface Props {
  redeemJerryCanModal: boolean;
  handleUpdateRedeemJerryCanModal: (visible: boolean) => void;
}

interface State {
  monthlyAnalytics: AgentAnalyticsModel[];
}

class History extends Component<Props> {
  state: State = {
    monthlyAnalytics: [],
  };

  componentDidMount = async () => {
    const monthlyAnalytics = await getMonthsAnalytics();
    if (monthlyAnalytics && typeof monthlyAnalytics !== "string") {
      this.setState({
        monthlyAnalytics,
      });
    }
  };

  renderOptionsGenerator = (isCarbon: boolean) => {
    const dateList = [];
    const today = moment();
    dateList.push(today.format("MMMM"));
    for (let i = 0; i < 3; i++) {
      const subtractDate = moment(today).subtract(i + 1, "month");
      dateList.push(subtractDate.format("MMMM"));
    }
    dateList.reverse();

    let valueList: number[] = [];
    if (this.state.monthlyAnalytics.length > 0) {
      dateList.map((eachMonth) => {
        let valuePresent = false;
        this.state.monthlyAnalytics.map((eachMonthAnalytics) => {
          if (
            moment(eachMonthAnalytics.createdDate).format("MMMM") === eachMonth
          ) {
            valuePresent = true;
            valueList.push(
              isCarbon
                ? Number(
                    (eachMonthAnalytics.monthlyUcoCollected * 3.17).toFixed(2)
                  )
                : eachMonthAnalytics.monthlyUcoCollected
            );
          }
          return null;
        });
        if (!valuePresent) {
          valueList.push(0);
        }
        return null;
      });
    } else {
      valueList = [0, 0, 0, 0];
    }

    return {
      series: [
        {
          name: "Amount",
          data: valueList,
        },
      ],
      option: {
        fill: { colors: ["#6dba62"] },
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: number) {
            return val.toFixed(1) + " kg";
          },
          offsetY: -30,
          style: {
            fontSize: "12px",
            colors: ["#6dba62"],
          },
        },
        xaxis: {
          categories: dateList,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: [],
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold",
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
        tooltips: { enabled: false },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val: number) {
              return val.toFixed(1) + " kg";
            },
          },
        },
      },
    };
  };

  renderDashboardContent = (isCarbon: boolean) => {
    const data = this.renderOptionsGenerator(isCarbon);

    return (
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="p-5">
          <div className="flex">
            <div className="flex-shrink-0">
              <span className="flex p-2 mt-1 rounded-lg bg-arusgreen-600">
                <ChartPieIcon className="h-5 w-5 text-white" />
              </span>
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="text-md font-medium text-gray-500 truncate">
                  {isCarbon ? "Carbon Savings" : " Used Cooking Oil"} Collected
                  Per Month
                </dt>
                <dd>
                  <div className="font-medium text-gray-900 mt-2">
                    <ReactApexChart
                      options={data.option}
                      series={data.series}
                      type="bar"
                      height={280}
                    />
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMonthlyAnalytics = () => {
    const tableData: any[] = [];
    if (this.state.monthlyAnalytics.length > 0) {
      this.state.monthlyAnalytics.map((eachMonthAnalytics, index) => {
        const amountCollected =
          eachMonthAnalytics.monthlyUcoCollected.toFixed(2);
        tableData.push({
          id: index,
          description: `All redeemed users have collected a total of ${amountCollected} kg of used cooking oil in ${moment(
            eachMonthAnalytics.createdDate
          ).format("MMMM YYYY")}`,
          amount: amountCollected,
        });
        return null;
      });
    }
    return tableData;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "description",
        title: "Description",
      },
      {
        key: "amount",
        title: "Amount(KG)",
      },
    ];

    return (
      <>
        <UpdateJerryCanModal
          open={this.props.redeemJerryCanModal}
          onClose={this.props.handleUpdateRedeemJerryCanModal.bind(this, false)}
        />
        <div className="mt-8">
          <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
            <h2 className="text-lg leading-6 font-medium text-gray-900">
              Recent Monthly Analytics
            </h2>
            <div className="mt-2 grid grid-cols-1 gap-5">
              {this.renderDashboardContent(false)}
            </div>
            <div className="mt-2 grid grid-cols-1 gap-5">
              {this.renderDashboardContent(true)}
            </div>
          </div>

          <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
            Last 6 Months Analytics
          </h2>
          <Table
            pageIndex={1}
            lastCursor=""
            header={headers}
            data={this.renderMonthlyAnalytics()}
            dataLimit={10}
          />
        </div>
      </>
    );
  }
}

export default History;
