import { AgentState, AgentStateError } from "../../pages/Login";
import firebase from "../../utils/firebase";

//INFO : Auth Listener
let unsubscribeAuthListener: any = null;
let unsubscribeUserListener: any = null;

export const getAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (!unsubscribeAuthListener) {
      unsubscribeAuthListener = firebase
        .auth()
        .onAuthStateChanged(function (user) {
          if (user) {
            dispatch({
              type: "UPDATE_USER_AUTH",
              payload: {
                userAuth: user,
              },
            });
            getUserInfoListener(dispatch);
          } else {
            dispatch({
              type: "UPDATE_USER_AUTH",
              payload: {
                userAuth: null,
              },
            });
            removeUserInfoListener();
          }
        });
    }
  };
};

export const removeAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (unsubscribeAuthListener) {
      unsubscribeAuthListener();
      unsubscribeAuthListener = null;
    }
    removeUserInfoListener();
  };
};

export const resetPassword = async (email: string) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const signIn = async (credentials: {
  email: string;
  password: string;
}) => {
  try {
    await firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password);
    return "";
  } catch (err: any) {
    return err.message;
  }
};

export const signOut = async () => {
  try {
    await firebase.auth().signOut();
  } catch (err) {}
};

const getUserInfoListener = async (dispatch: any) => {
  try {
    if (firebase.auth().currentUser?.uid) {
      try {
        const agentQuery = await firebase
          .firestore()
          .collection("agents")
          .doc(firebase.auth().currentUser?.uid)
          .get();
        if (!agentQuery.exists) {
          signOut();
        }
      } catch (err) {
        signOut();
      }

      unsubscribeUserListener = firebase
        .firestore()
        .collection("agents")
        .doc(firebase.auth().currentUser?.uid)
        .onSnapshot(async (doc) => {
          if (doc) {
            dispatch({
              type: "UPDATE_USER",
              payload: {
                user: doc.data(),
              },
            });
          }
        });
    }
  } catch (err) {}
};

const removeUserInfoListener = () => {
  if (unsubscribeUserListener) {
    unsubscribeUserListener();
    unsubscribeUserListener = null;
  }
};

export const handleAuthCondition = (
  agentAttributeState: AgentState,
  agentAttributeError: AgentStateError,
  typeList: string[]
) => {
  typeList.map((eachType) => {
    switch (eachType) {
      case "email":
        /* eslint-disable */
        const filter =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        /* eslint-enable */
        if (!filter.test(agentAttributeState.email.replace(/\s/g, ""))) {
          agentAttributeError["emailError"] =
            "Please enter your email in the correct format";
        } else {
          agentAttributeError["emailError"] = "";
        }
        break;
      case "password":
        if (agentAttributeState.password.replace(/\s/g, "").length < 6) {
          agentAttributeError["passwordError"] =
            "Please enter at least 6 characters";
        } else {
          agentAttributeError["passwordError"] = "";
        }
        break;
    }
    return null;
  });
};
