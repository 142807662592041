import React, { Component } from "react";
import { connect } from "react-redux";
import Analytics from "../components/dashboard/Analytics";
import Greeting from "../components/dashboard/Greetings";
import Header from "../components/dashboard/Header";
import History from "../components/dashboard/History";
import Home from "../components/dashboard/Home";
import LeftNav, { DashboardPage } from "../components/dashboard/LeftNav";
import SecurityNavigator from "../navigator/SecurityNavigator";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  history: any;
  location: any;
  authStore: authStateInterface;
}

interface State {
  leftNavMobileMenu: boolean;
  page: DashboardPage;
  redeemJerryCanModal: boolean;
}

class Dashboard extends Component<Props> {
  state: State = {
    leftNavMobileMenu: false,
    page: DashboardPage.HOME,
    redeemJerryCanModal: false,
  };

  componentDidMount() {
    this.handlePageNavigation();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handlePageNavigation();
    }
  }

  handlePageNavigation = () => {
    const path = window.location.pathname;
    let page: DashboardPage = DashboardPage.HOME;
    if (path.includes("dashboard/histories")) {
      page = DashboardPage.HISTORY;
    } else if (path.includes("dashboard/analytics")) {
      page = DashboardPage.ANALYTICS;
    }
    this.setState({
      page,
    });
  };

  handleToggleLeftNavMobileMenu = () => {
    this.setState({
      leftNavMobileMenu: !this.state.leftNavMobileMenu,
    });
  };

  handleUpdateRedeemJerryCanModal = (redeemJerryCanModal: boolean) => {
    this.setState({
      redeemJerryCanModal,
    });
  };

  renderContent = () => {
    switch (this.state.page) {
      case DashboardPage.HOME:
        return (
          <Home
            redeemJerryCanModal={this.state.redeemJerryCanModal}
            handleUpdateRedeemJerryCanModal={
              this.handleUpdateRedeemJerryCanModal
            }
          />
        );
      case DashboardPage.HISTORY:
        return (
          <History
            redeemJerryCanModal={this.state.redeemJerryCanModal}
            handleUpdateRedeemJerryCanModal={
              this.handleUpdateRedeemJerryCanModal
            }
          />
        );
      case DashboardPage.ANALYTICS:
        return <Analytics />;
      default:
        return null;
    }
  };

  renderCallToActionText = () => {
    switch (this.state.page) {
      case DashboardPage.HOME:
      case DashboardPage.HISTORY:
        return "Redeem Jerry Cans";
      default:
        return "";
    }
  };

  renderCallToActionFunction = () => {
    switch (this.state.page) {
      case DashboardPage.HOME:
      case DashboardPage.HISTORY:
        return this.setState({
          redeemJerryCanModal: !this.state.redeemJerryCanModal,
        });
      default:
        return "";
    }
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }
    return (
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <SecurityNavigator location={this.props.location} />
        <LeftNav
          leftNavMobileMenu={this.state.leftNavMobileMenu}
          handleToggleLeftNavMobileMenu={this.handleToggleLeftNavMobileMenu}
          page={this.state.page}
        />
        <div className="flex-1 overflow-auto focus:outline-none">
          <Header
            handleToggleLeftNavMobileMenu={this.handleToggleLeftNavMobileMenu}
          />
          <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
            <Greeting
              callToActionText={this.renderCallToActionText()}
              callToActionFunction={this.renderCallToActionFunction}
            />
            {this.renderContent()}
          </main>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(Dashboard);
