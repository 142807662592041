import { CheckIcon } from "@heroicons/react/solid";
import React, { Component } from "react";
import { getUsersWithSearchText } from "../../store/actions/userActions";

export interface Option {
  key: string;
  title: string;
}

interface State {
  onFocus: boolean;
  options: Option[];
}

interface Props {
  id: string;
  title: string;
  value: string;
  promoCodes: string[];
  className?: string;
  error?: string;
  placeholder?: string;
  onChange?: (id: string, key: string) => void;
}

export default class Select extends Component<Props> {
  typingTimeout: ReturnType<typeof setTimeout> | undefined;

  state: State = {
    onFocus: false,
    options: [],
  };

  handleFocus = () => {
    this.setState({
      onFocus: true,
    });
  };

  handleBlur = () => {
    this.setState({
      onFocus: false,
    });
  };

  handleGetSuggestions = async () => {
    const showingOptions: Option[] = [];
    const options = await getUsersWithSearchText(this.props.value);
    if (options.length > 0) {
      options.map((eachUser) => {
        let promoPresent = false;
        this.props.promoCodes.map((eachPromoCode) => {
          if (eachUser.promotions && eachUser.promotions.length > 0) {
            eachUser.promotions.map((eachPromotion) => {
              if (eachPromotion.id === eachPromoCode) {
                promoPresent = true;
              }
              return null;
            });
          }
          return null;
        });
        if (promoPresent) {
          showingOptions.push({
            key: eachUser.email,
            title: eachUser.email,
          });
        }

        return null;
      });
    }

    this.setState({
      options: showingOptions,
    });
  };

  handleOnChange = (e: any) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.id, e.target.value);
    }

    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }

    this.typingTimeout = setTimeout(() => {
      this.handleGetSuggestions();
    }, 250);
  };

  renderOptions = () => {
    let optionView: any = [];
    this.state.options.map((eachOption) => {
      optionView.push(
        <li
          key={eachOption.key}
          className="text-gray-900 cursor-default select-none relative px-5 py-3 hover:bg-arusgreen-300 group"
          onMouseDown={this.props.onChange?.bind(
            this,
            this.props.id,
            eachOption.key
          )}
        >
          <span className="font-normal block truncate group-hover:text-white">
            {eachOption.title}
          </span>
          {this.props.value === eachOption.key && (
            <span className="text-arusgreen-600 absolute inset-y-0 right-0 flex items-center pr-4 group-hover:text-white">
              <CheckIcon className="h-5 w-5" />
            </span>
          )}
        </li>
      );
      return null;
    });

    return optionView;
  };

  render() {
    let defaultClassName =
      "block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 focus:border-arusgreen-600 focus:outline-none";
    if (this.props.className) {
      defaultClassName += ` ${this.props.className}`;
    }
    if (this.props.error) {
      defaultClassName += " mb-0.5 border-red-300";
    }

    return (
      <div className="w-full">
        <div className="relative">
          <div className="flex justify-between">
            <label className="block text-sm font-medium text-gray-700">
              {this.props.title}
            </label>
          </div>
          <label className="sr-only">{this.props.title}</label>
          <div>
            <input
              id={this.props.id}
              className={defaultClassName}
              value={this.props.value}
              placeholder={this.props.placeholder}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleOnChange}
            />
            {this.state.onFocus && (
              <ul className="absolute z-10 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {this.renderOptions()}
              </ul>
            )}
          </div>

          <span className="text-red-500 text-sm">{this.props.error}</span>
        </div>
      </div>
    );
  }
}
