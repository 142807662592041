import React, { Component } from "react";
import { connect } from "react-redux";
import packageJson from "../../package.json";
import Button from "../components/base/Button";
import Input from "../components/base/Input";
import Overlay from "../components/base/Overlay";
import {
  handleAuthCondition,
  resetPassword,
  signIn,
} from "../store/actions/authActions";
import { authStateInterface } from "../store/reducers/authReducer";
import SceneryBackground from "./../images/bg_login.webp";
import Favicon from "./../images/favicon.png";
import SecurityNavigator from "./../navigator/SecurityNavigator";

export enum authType {
  SIGNIN = "signin",
  RESET = "resetPassword",
}

export interface AgentState {
  email: string;
  password: string;
}

export interface AgentStateError {
  emailError: string;
  passwordError: string;
}

interface State {
  type: authType;
  loading: boolean;
  agentState: AgentState;
  agentStateError: AgentStateError;
}

interface Props {
  location: any;
  authStore: authStateInterface;
}

class Login extends Component<Props> {
  state: State = {
    type: authType.SIGNIN,
    loading: false,
    agentState: {
      email: "",
      password: "",
    },
    agentStateError: {
      emailError: "",
      passwordError: "",
    },
  };

  handleInputChange = (e: any) => {
    const clonedAgentState = JSON.parse(JSON.stringify(this.state.agentState));
    clonedAgentState[e.target.id] = e.target.value;
    this.setState({
      agentState: clonedAgentState,
    });
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading,
    });
  };

  handleSubmit = async () => {
    let conditionsList: string[] = [];
    if (this.state.type === authType.SIGNIN) {
      conditionsList = ["email", "password"];
    } else if (this.state.type === authType.RESET) {
      conditionsList = ["email"];
    }

    const clonedAdminError = JSON.parse(
      JSON.stringify(this.state.agentStateError)
    );

    handleAuthCondition(
      this.state.agentState,
      clonedAdminError,
      conditionsList
    );

    this.setState(
      {
        agentStateError: clonedAdminError,
      },
      async () => {
        if (this.state.type === authType.SIGNIN) {
          if (
            !this.state.agentStateError.emailError &&
            !this.state.agentStateError.passwordError
          ) {
            this.handleLoading(true);
            const signInError = await signIn({
              email: this.state.agentState.email.replace(/\s/g, ""),
              password: this.state.agentState.password.replace(/\s/g, ""),
            });
            const clonedSignInError = JSON.parse(
              JSON.stringify(this.state.agentStateError)
            );
            clonedSignInError["emailError"] = signInError ? signInError : "";
            this.setState({
              agentStateError: clonedSignInError,
            });
            this.handleLoading(false);
          }
        } else if (this.state.type === authType.RESET) {
          if (!this.state.agentStateError.emailError) {
            this.handleLoading(true);
            const resetPasswordError = await resetPassword(
              this.state.agentState.email
            );
            const resetAdminError = JSON.parse(
              JSON.stringify(this.state.agentStateError)
            );
            if (resetPasswordError) {
              resetAdminError["emailError"] = resetPasswordError;
            } else {
              resetAdminError["emailError"] =
                "Password reset link has been sent to your email";
            }
            this.setState({
              agentStateError: resetAdminError,
            });
            this.handleLoading(false);
          }
        }
      }
    );
  };

  handleChangeAuthType = (type: authType) => {
    this.setState({
      type,
      agentState: {
        email: "",
        password: "",
      },
    });
  };

  renderAuthForm() {
    if (this.state.type === authType.SIGNIN) {
      return (
        <>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <div className="mt-1">
              <Input
                id="email"
                className="mt-1"
                placeholder="Enter your email"
                value={this.state.agentState.email}
                error={this.state.agentStateError.emailError}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="space-y-1">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div className="mt-1">
              <Input
                id="password"
                type="password"
                className="mt-1"
                placeholder="Enter your password"
                value={this.state.agentState.password}
                error={this.state.agentStateError.passwordError}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm cursor-pointer">
              <span
                className="font-medium text-arusgreen-600 hover:text-arusgreen-500"
                onClick={this.handleChangeAuthType.bind(this, authType.RESET)}
              >
                Forgot your password?
              </span>
            </div>
          </div>

          <div>
            <Button
              type="normal"
              text="Sign in"
              onClick={this.handleSubmit}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-arusgreen-600 hover:bg-arusgreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-arusgreen-500"
            />
          </div>
          <div className="leading-5">
            <span className="text-xs text-gray-500">
              By signing in, you agree to accept Arus Oil x JMBs programme{" "}
              <a
                className="font-bold cursor-pointer text-arusgreen-600 hover:text-arusgreen-500"
                target="_blank"
                rel="noreferrer"
                href="https://arusoil.com/termscondition"
              >
                terms and conditions
              </a>{" "}
              and your account's{" "}
              <a
                className="font-bold cursor-pointer text-arusgreen-600 hover:text-arusgreen-500"
                target="_blank"
                rel="noreferrer"
                href="https://arusoil.com/privacypolicy"
              >
                privacy policy
              </a>
              . For more questions on the programme, please{" "}
              <a
                className="font-bold cursor-pointer text-arusgreen-600 hover:text-arusgreen-500"
                target="_blank"
                rel="noreferrer"
                href="https://drive.google.com/file/d/1TjdPXsxt0xp43Xb1BSgOZAZKYoj_O3EZ/view?usp=sharing"
              >
                refer here
              </a>
            </span>
          </div>
          <div className="sm:col-span-2 sm:flex sm:justify-end">
            <span className="text-xs text-gray-500">
              Version {packageJson.version}
            </span>
          </div>
        </>
      );
    } else if (this.state.type === authType.RESET) {
      return (
        <>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <div className="mt-1">
              <Input
                id="email"
                className="mt-1"
                placeholder="Enter your email"
                value={this.state.agentState.email}
                error={this.state.agentStateError.emailError}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm cursor-pointer">
              <span
                className="font-medium text-arusgreen-600 hover:text-arusgreen-500"
                onClick={this.handleChangeAuthType.bind(this, authType.SIGNIN)}
              >
                Remembered Your Password?
              </span>
            </div>
          </div>
          <div>
            <Button
              type="normal"
              text="Reset Password"
              onClick={this.handleSubmit}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-arusgreen-600 hover:bg-arusgreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-arusgreen-500"
            />
          </div>
        </>
      );
    }
  }

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    return (
      <div className="min-h-screen bg-white flex">
        <SecurityNavigator location={this.props.location} />
        <Overlay
          loading={this.state.loading}
          text="Please hold on while we are verifying your account"
        />
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-12 w-auto" src={Favicon} alt="Arus Oil" />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Arus Oil x JMBs Portal
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form action="#" method="POST" className="space-y-6">
                  {this.renderAuthForm()}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={SceneryBackground}
            alt=""
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(Login);
