import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import {
  getAuthListener,
  removeAuthListener,
} from "../store/actions/authActions";

declare global {
  interface Date {
    seconds: number;
  }
}

interface Props {
  getAuthListener: () => void;
  removeAuthListener: () => void;
}

class Navigator extends Component<Props> {
  componentDidMount() {
    this.props.getAuthListener();
  }

  componentWillUnmount() {
    this.props.removeAuthListener();
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/dashboard/histories" exact component={Dashboard} />
          <Route path="/dashboard/analytics" exact component={Dashboard} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAuthListener: () => dispatch(getAuthListener()),
    removeAuthListener: () => dispatch(removeAuthListener()),
  };
};

export default connect(null, mapDispatchToProps)(Navigator);
