import {
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  UserAddIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import AgentAnalyticsModel, {
  CodeAnalytics,
} from "../../models/AgentAnalytics";
import { getMonthsAnalytics } from "../../store/actions/analyticsActions";
import { authStateInterface } from "../../store/reducers/authReducer";
import Select, { Option } from "../base/Select";

interface Props {
  authStore: authStateInterface;
}

interface State {
  selectedPromo: string;
  monthlyAnalytics: AgentAnalyticsModel[];
}

class Analytics extends Component<Props> {
  state: State = {
    selectedPromo: "ALL",
    monthlyAnalytics: [],
  };

  componentDidMount = async () => {
    const monthlyAnalytics = await getMonthsAnalytics();
    if (monthlyAnalytics && typeof monthlyAnalytics !== "string") {
      this.setState({
        monthlyAnalytics,
      });
    }
  };

  handleSelectChange = (id: string, key: string) => {
    this.setState({
      [id]: key,
    });
  };

  renderDashboardContent = () => {
    let selectedAnalytics: AgentAnalyticsModel = {
      id: "",
      userId: "",
      redeemedUser: 0,
      recyclingUser: 0,
      appointmentMade: 0,
      ucoCollected: 0,
      monthlyUcoCollected: 0,
      addressAnalytics: [],
      createdDate: moment().toDate(),
    };
    if (this.state.monthlyAnalytics.length > 0) {
      selectedAnalytics = JSON.parse(
        JSON.stringify(this.state.monthlyAnalytics[0])
      );
    }

    let updatedDate = moment().format("DD MMMM YYYY ha");
    if (selectedAnalytics.id) {
      updatedDate = moment(selectedAnalytics.createdDate).format(
        "DD MMMM YYYY ha"
      );

      let codeAnalytics: CodeAnalytics | undefined;
      if (this.state.selectedPromo !== "ALL") {
        if (selectedAnalytics.codeAnalytics) {
          codeAnalytics =
            selectedAnalytics.codeAnalytics[this.state.selectedPromo];
          if (codeAnalytics) {
            selectedAnalytics.redeemedUser = codeAnalytics.redeemedUser;
            selectedAnalytics.recyclingUser = codeAnalytics.recyclingUser;
            selectedAnalytics.appointmentMade = codeAnalytics.appointmentMade;
            selectedAnalytics.ucoCollected = codeAnalytics.ucoCollected;
          }
        }
        if (!codeAnalytics) {
          selectedAnalytics.redeemedUser = 0;
          selectedAnalytics.recyclingUser = 0;
          selectedAnalytics.appointmentMade = 0;
          selectedAnalytics.ucoCollected = 0;
        }
      }
    }

    const content = [
      {
        icon: <UserGroupIcon className="h-5 w-5 text-white" />,
        title: "Redeemed Users",
        content: selectedAnalytics.redeemedUser,
        span: `Updated On : ${updatedDate}`,
      },
      {
        icon: <UserAddIcon className="h-5 w-5 text-white" />,
        title: "Recycling Users",
        content: selectedAnalytics.recyclingUser,
        span: `Updated On : ${updatedDate}`,
      },
      {
        icon: <CalendarIcon className="h-5 w-5 text-white" />,
        title: "Appointments Made",
        content: selectedAnalytics.appointmentMade,
        span: `Updated On : ${updatedDate}`,
      },
      {
        icon: <BellIcon className="h-5 w-5 text-white" />,
        title: "Used Cooking Oil Collected(kg)",
        content: selectedAnalytics.ucoCollected.toFixed(2),
        span: `Updated On : ${updatedDate}`,
      },
    ];

    const contentViewList: any = [];
    content.map((eachContent, key: number) => {
      contentViewList.push(
        <div className="bg-white overflow-hidden shadow rounded-lg" key={key}>
          <div className="p-5">
            <div className="flex">
              <div className="flex-shrink-0">
                <span className="flex p-2 mt-1 rounded-lg bg-arusgreen-600">
                  {eachContent.icon}
                </span>
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-md font-medium text-gray-500 truncate">
                    {eachContent.title}
                  </dt>
                  <dd>
                    <p className="text-xs font-medium text-gray-500 truncate">
                      {eachContent.span}
                    </p>
                    <div className={`text-lg font-medium text-gray-900 mt-2`}>
                      {eachContent.content}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
      return null;
    });
    return contentViewList;
  };

  renderOptionsGenerator = (isCarbon: boolean) => {
    const dateList = [];
    const today = moment();
    dateList.push(today.format("MMMM"));
    for (let i = 0; i < 3; i++) {
      const subtractDate = moment(today).subtract(i + 1, "month");
      dateList.push(subtractDate.format("MMMM"));
    }
    dateList.reverse();

    let valueList: number[] = [];
    if (this.state.monthlyAnalytics.length > 0) {
      dateList.map((eachMonth) => {
        let valuePresent = false;
        this.state.monthlyAnalytics.map((eachMonthAnalytics) => {
          if (
            moment(eachMonthAnalytics.createdDate).format("MMMM") === eachMonth
          ) {
            valuePresent = true;
            if (this.state.selectedPromo !== "ALL") {
              let codeAnalytics: CodeAnalytics | undefined;
              if (eachMonthAnalytics.codeAnalytics) {
                codeAnalytics =
                  eachMonthAnalytics.codeAnalytics[this.state.selectedPromo];
                if (codeAnalytics) {
                  valueList.push(
                    isCarbon
                      ? Number(
                          (codeAnalytics.monthlyUcoCollected * 3.17).toFixed(2)
                        )
                      : codeAnalytics.monthlyUcoCollected
                  );
                }
              }
              if (!codeAnalytics) {
                valueList.push(0);
              }
            } else {
              valueList.push(
                isCarbon
                  ? Number(
                      (eachMonthAnalytics.monthlyUcoCollected * 3.17).toFixed(2)
                    )
                  : eachMonthAnalytics.monthlyUcoCollected
              );
            }
          }
          return null;
        });
        if (!valuePresent) {
          valueList.push(0);
        }
        return null;
      });
    } else {
      valueList = [0, 0, 0, 0];
    }

    return {
      series: [
        {
          name: "Amount",
          data: valueList,
        },
      ],
      option: {
        fill: { colors: ["#6dba62"] },
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: number) {
            return val.toFixed(1) + " kg";
          },
          offsetY: -30,
          style: {
            fontSize: "12px",
            colors: ["#6dba62"],
          },
        },
        xaxis: {
          categories: dateList,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: [],
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "bold",
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
        tooltips: { enabled: false },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val: number) {
              return val.toFixed(1) + " kg";
            },
          },
        },
      },
    };
  };

  renderDashboardCharts = (isCarbon: boolean) => {
    const data = this.renderOptionsGenerator(isCarbon);

    return (
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="p-5">
          <div className="flex">
            <div className="flex-shrink-0">
              <span className="flex p-2 mt-1 rounded-lg bg-arusgreen-600">
                <ChartPieIcon className="h-5 w-5 text-white" />
              </span>
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="text-md font-medium text-gray-500 truncate">
                  {isCarbon ? "Carbon Savings" : "Used Cooking Oil Collected"}{" "}
                  Per Month
                </dt>
                <dd>
                  <div className="font-medium text-gray-900 mt-2">
                    <ReactApexChart
                      options={data.option}
                      series={data.series}
                      type="bar"
                      height={280}
                    />
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const options: Option[] = [
      {
        key: "ALL",
        title: "ALL",
      },
    ];
    if (this.props.authStore.user) {
      this.props.authStore.user.promoCodes.map((eachCode) => {
        options.push({
          key: eachCode,
          title: eachCode,
        });
        return null;
      });
    }

    return (
      <div className="mt-8">
        <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:items-center sm:justify-center sm:flex-row">
            <h2 className="text-lg leading-6 font-medium text-gray-900">
              Promo Code Analytics
            </h2>
            <div className="flex-1" />
            <div className="w-auto sm:w-40">
              <Select
                id="selectedPromo"
                placeholder={"Promo Code"}
                value={this.state.selectedPromo}
                error={""}
                options={options}
                onChange={this.handleSelectChange}
              />
            </div>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
            {this.renderDashboardContent()}
          </div>
          <h2 className="mt-8 text-lg leading-6 font-medium text-gray-900">
            Recent Monthly Analytics
          </h2>
          <div className="mt-2 grid grid-cols-1 gap-5">
            {this.renderDashboardCharts(false)}
          </div>
          <div className="mt-2 grid grid-cols-1 gap-5">
            {this.renderDashboardCharts(true)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(Analytics);
