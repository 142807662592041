import { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { authStateInterface } from "../store/reducers/authReducer";

interface Props {
  authStore: authStateInterface;
  location: any;
  role?: string;
}

class SecurityNavigator extends Component<Props> {
  render() {
    if (!this.props.authStore.userLoading) {
      if (!this.props.authStore.userAuth) {
        return <Redirect to="/" />;
      } else if (
        this.props.authStore.userAuth &&
        this.props.authStore.user?.email &&
        this.props.location.pathname === "/"
      ) {
        return <Redirect to="/dashboard" />;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(SecurityNavigator);
