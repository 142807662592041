import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyUserRedemption } from "../../store/actions/userActions";
import { authStateInterface } from "../../store/reducers/authReducer";
import Button from "../base/Button";
import Select from "../base/Select";
import SmartSelect from "../base/SmartSelect";

interface Props {
  open: boolean;
  onClose: (visible: boolean) => void;
  authStore: authStateInterface;
}

interface State {
  email: string;
  emailError: string;
  station: string;
}

const shellStations = [
  {
    key: "MASJID",
    title: "Masjid Negeri",
  },
  {
    key: "FERRY",
    title: "Chain Ferry 2",
  },
  {
    key: "SIMPANG",
    title: "Simpang Ampat",
  },
];

class UpdateJerryCanModal extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;
  state: State = {
    email: "",
    emailError: "",
    station: "",
  };

  handleClose = () => {
    this.setState({
      email: "",
      emailError: "",
    });
    this.props.onClose(false);
  };

  handleSelectChange = (id: string, key: string) => {
    this.setState({
      [id]: key,
    });
  };

  handleEmailChange = (id: string, email: string) => {
    this.setState({
      [id]: email,
    });
  };

  handlePreventPropagation = (event: any) => {
    event.stopPropagation();
  };

  handleCheckConfirmation = async () => {
    /* eslint-disable */
    const filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    /* eslint-enable */
    if (!filter.test(this.state.email.replace(/\s/g, ""))) {
      this.setState({
        emailError: "Please enter the user's email in the correct format",
      });
    } else {
      this.setState({ emailError: "" });
      const verificationError = await verifyUserRedemption(
        this.state.email,
        this.state.station,
        this.props.authStore.user?.promoCodes ?? []
      );
      if (verificationError) {
        this.setState({
          emailError: verificationError,
        });
      } else {
        this.handleClose();
        window.alert("This user has successfully redeemed his/her jerry can!");
      }
    }
  };

  renderContent = () => {
    return (
      <div className="grid grid-cols-1 gap-y-6 p-6 md:p-8">
        <div className="col-span-1">
          <label className="block text-lg font-medium text-gray-900">
            Verify A User's Redemption
          </label>
          <label className="block mt-1 mb-5 text-sm font-medium text-gray-600">
            When a user redeems his/her jerry can for storing used cooking oil,
            search the user's registered email and click "Redeemed" to prevent
            double redemption.
          </label>
          <SmartSelect
            id="email"
            title="Users' Email"
            placeholder="xxxxx@gmail.com"
            value={this.state.email}
            error={this.state.emailError}
            onChange={this.handleEmailChange}
            promoCodes={this.props.authStore.user?.promoCodes ?? []}
          />
          {this.props.authStore.user?.promoCodes.includes("SHELL") && (
            <>
              <label className="block mt-4 text-sm font-medium text-gray-700">
                Redeemed Station
              </label>
              <Select
                id="station"
                placeholder={"Shell Station"}
                value={this.state.station}
                error={""}
                options={shellStations}
                onChange={this.handleSelectChange}
              />
            </>
          )}
        </div>
        <div className="mt-5 flex justify-end">
          <Button
            className="w-full sm:w-auto"
            text="Redeemed"
            type="normal"
            onClick={this.handleCheckConfirmation}
          />
        </div>
      </div>
    );
  };

  render() {
    if (this.props.open) {
      return (
        <>
          <div className="fixed top-0 left-0 z-20 w-full h-full flex items-center justify-center bg-gray-700 opacity-70" />
          <div
            className="fixed top-0 left-0 z-30 flex items-center justify-center w-full h-full"
            onClick={this.handleClose}
          >
            <div
              className="bg-white max-w-2xl h-auto w-11/12 md:w-7/12 rounded-lg shadow"
              onClick={this.handlePreventPropagation}
            >
              {this.renderContent()}
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(UpdateJerryCanModal);
