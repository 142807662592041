import { UserModel } from "../../models/Users";
import firebase from "../../utils/firebase";

export const verifyUserRedemption = async (
  email: string,
  station: string,
  promoCodes: string[]
) => {
  try {
    const usersSnapshot = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .limit(1)
      .get();

    let selectedUser: UserModel | undefined;
    if (usersSnapshot) {
      usersSnapshot.forEach((eachUser) => {
        selectedUser = eachUser.data() as UserModel;
      });
    }

    if (selectedUser) {
      let promoPresent = false;
      promoCodes.map((eachPromoCode) => {
        if (selectedUser?.promotions && selectedUser.promotions.length > 0) {
          selectedUser.promotions.map((eachPromotion) => {
            if (eachPromotion.id === eachPromoCode) {
              promoPresent = true;
            }
            return null;
          });
        }
        return null;
      });
      if (selectedUser.jerryCanRedeem) {
        return "This user has redeemed its jerry can";
      } else if (promoPresent) {
        const updatingData: any = {
          jerryCanRedeem: new Date(),
        };
        if (station) {
          updatingData["jerryCanRedeemStation"] = station;
        }
        await firebase
          .firestore()
          .collection("users")
          .doc(selectedUser.id)
          .update(updatingData);
        return "";
      } else {
        return "This user is not registered under your JMB";
      }
    } else {
      return "User cannot be found";
    }
  } catch (err: any) {
    return err.message;
  }
};

export const getUsersWithSearchText = async (searchText: string) => {
  const selectedUsers: UserModel[] = [];
  try {
    if (firebase.auth().currentUser?.uid) {
      const usersSnapshot = await firebase
        .firestore()
        .collection("users")
        .limit(10)
        .orderBy("email")
        .startAt(searchText)
        .endAt(searchText + "\uf8ff")
        .get();

      if (usersSnapshot) {
        usersSnapshot.forEach((eachUser) => {
          const selectedUser = eachUser.data() as UserModel;
          selectedUsers.push(selectedUser);
        });
      }
    }
    return selectedUsers;
  } catch (err) {
    return [];
  }
};
